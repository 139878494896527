import React from 'react';
import ApplicationLogo from '@/Components/ApplicationLogo';
import { Link } from '@inertiajs/react';
import { Button } from "@/Components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/Components/ui/dropdown-menu";
import { User, Dog, Calendar, CreditCard, DoorOpen, User2 } from 'lucide-react';
import { User as UserType } from '@/types'

interface HeaderProps {
    user?: UserType | null;
}

const Header: React.FC<HeaderProps> = ({ user }) => {
    return (
        <header className="flex items-center justify-between px-6 py-4 bg-white shadow-md">
            {/* Logo */}
            <div className="flex items-center">
                <a href='/'>
                    <ApplicationLogo className="w-32 pb-4 ml-4" />
                </a>
            </div>

            {/* Navigation Menu */}
            <nav className="hidden md:flex space-x-8">
                <a href="/how-it-works" className="text-gray-600 hover:text-gray-900">How It Works</a>
                <a href="/locations" className="text-gray-600 hover:text-gray-900">Our Fields</a>
                <a href="/start-a-field" className="text-gray-600 hover:text-gray-900">Start A Field</a>
            </nav>

            {/* Auth Buttons or User Dropdown */}
            <div className="flex space-x-4">
                {user ? (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" className="flex items-center space-x-2 cursor-pointer">
                                <User className="w-4 h-4" />
                                <span>{user.name}</span>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56">
                            <Link href="/account/membership">
                                <DropdownMenuItem className="cursor-pointer">
                                    <User className="mr-2 h-4 w-4" />
                                    <span>My Profile</span>
                                </DropdownMenuItem>
                            </Link>
                            <Link href="/account/dogs">
                                <DropdownMenuItem className="cursor-pointer">
                                    <Dog className="mr-2 h-4 w-4" />
                                    <span>My Dogs</span>
                                </DropdownMenuItem>
                            </Link>
                            <Link href="/account/history">
                                <DropdownMenuItem className="cursor-pointer">
                                    <Calendar className="mr-2 h-4 w-4" />
                                    <span>My Bookings</span>
                                </DropdownMenuItem>
                            </Link>
                            <Link href="/account/methods">
                                <DropdownMenuItem className="cursor-pointer">
                                    <CreditCard className="mr-2 h-4 w-4" />
                                    <span>My Payment Methods</span>
                                </DropdownMenuItem>
                            </Link>

                            {user.type !== 'customer' && (
                                <>
                                    <DropdownMenuSeparator />
                                    <Link href="/dashboard">
                                        <DropdownMenuItem className="cursor-pointer">
                                            <User2 className="mr-2 h-4 w-4" />
                                            <span>Go To Admin</span>
                                        </DropdownMenuItem>
                                    </Link>
                                </>    
                            )}

                            {user.is_franchise_user && (
                                <>
                                    <DropdownMenuSeparator />
                                    <Link href="/franchisee">
                                        <DropdownMenuItem className="cursor-pointer">
                                            <User2 className="mr-2 h-4 w-4" />
                                            <span>Go To Franchise Admin</span>
                                        </DropdownMenuItem>
                                    </Link>
                                </>    
                            )}

                            <DropdownMenuSeparator />
                            <Link href="/logout">
                                <DropdownMenuItem className="cursor-pointer">
                                    <DoorOpen className="mr-2 h-4 w-4" />
                                    <span>Logout</span>
                                </DropdownMenuItem>
                            </Link>
                        </DropdownMenuContent>
                    </DropdownMenu>
                ) : (
                    <>
                        <Link href="/login">
                            <Button className='bg-brand text-white border-1' variant="outline">Login</Button>
                        </Link>
                        <Link href="/register">
                            <Button className='bg-dark'>Register</Button>
                        </Link>
                    </>
                )}
            </div>
        </header>
    );
};

export default Header;