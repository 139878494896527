import React from 'react';
import { Facebook, Instagram, Twitter } from 'lucide-react';

const Footer: React.FC = () => {
    return (
        <footer className="bg-dark text-white">
            <div className="container mx-auto px-4 py-12">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {/* Company Info */}
                    <div>
                        <h3 className="text-lg font-semibold mb-4">Walkies</h3>
                        <p className="text-sm text-white">Providing safe and secure exercise fields for your furry friends since 2021.</p>
                    </div>

                    {/* Quick Links */}
                    <div>
                        <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
                        <ul className="text-sm text-white">
                            <li className="mb-2"><a href="/" className="hover:text-white">Home</a></li>
                            <li className="mb-2"><a href="/locations" className="hover:text-white">Our Fields</a></li>
                            <li className="mb-2"><a href="/how-it-works" className="hover:text-white">How It Works</a></li>
                            <li className="mb-2"><a href="/privacy" className="hover:text-white">Privacy Policy</a></li>
                            <li className="mb-2"><a href="/terms" className="hover:text-white">Terms &amp; Conditions</a></li>
                            <li className="mb-2"><a href="/noise" className="hover:text-white">Noise Complaints</a></li>
                        </ul>
                    </div>

                    {/* Contact Info */}
                    <div>
                        <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
                        <ul className="text-sm text-white">
                            <li className="mb-2">Phone: <a className='underline' href='tel:01992911333'>01992 911 333</a></li>
                            <li className="mb-2">Email: <a className='underline' href='mailto:info@grow-walkies.com'>info@grow-walkies.com</a></li>
                        </ul>
                    </div>

                    {/* Social Media */}
                    <div>
                        <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
                        <div className="flex space-x-4">
                            <a href="https://www.facebook.com/growwalkies" className="text-white hover:text-white">
                                <Facebook className="w-6 h-6" />
                            </a>
                            <a href="https://www.instagram.com/grow.walkies/" className="text-white hover:text-white">
                                <Instagram className="w-6 h-6" />
                            </a>
                        </div>
                    </div>
                </div>

                {/* Copyright */}
                <div className="mt-8 pt-8 border-t border-gray-500 text-center text-sm text-white">
                    © {new Date().getFullYear()} Grow Walkies Ltd. All rights reserved.
                </div>
            </div>
        </footer>
    );
};

export default Footer;